<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('set.phoneAuthentication') }}</div>
			</div>
		</div>
		<!-- <div class="cur_account">
			{{$t('curPhoneNum')}}：{{mobile_code}}-{{mobile}}
		</div> -->
		<div class="now_email">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/phone-1.png">
			</div>
			<div>
				<span class="email">{{ $t('curPhoneNum') }}：</span><span class="email_num">{{ mobile_code }}-{{ mobile
				}}</span>
			</div>
		</div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/code.png">
			</div>
			<div class="input">
				<input type="text" :placeholder="$t('login.inputCode')" v-model="code">
			</div>
			<div class="code">
				<div class="code1" @click="getCode" v-if="!isGetCode">{{ $t('login.getCode') }}</div>
				<div class="code2" v-else>{{ $t('login.sendAgain') }}：{{ time }}</div>
			</div>
		</div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/password.png">
			</div>
			<div class="input">
				<input :type="isShowPassword1 ? 'text' : 'password'" v-model="password"
					:placeholder="$t('set.inputNewPassword')">
			</div>
			<div class="show_icon" @click="isShowPassword1 = !isShowPassword1">
				<img src="../../../assets/login/show.png" v-if="isShowPassword1">
				<img src="../../../assets/login/hide.png" v-else>
			</div>
		</div>
		<div class="input_item">
			<div class="input_item_icon">
				<img src="../../../assets/mycenter/password.png">
			</div>
			<div class="input">
				<input :type="isShowPassword2 ? 'text' : 'password'" v-model="password2"
					:placeholder="$t('set.inputConfirmPasswordAgain')">
			</div>
			<div class="show_icon" @click="isShowPassword2 = !isShowPassword2">
				<img src="../../../assets/login/show.png" v-if="isShowPassword2">
				<img src="../../../assets/login/hide.png" v-else>
			</div>
		</div>
		<div class="btn" @click="forgetPassword()">
			{{ $t('confirm') }}
		</div>
	</div>
</template>

<script>
import {
	validate
} from '@/common/function/validate.js';

export default {
	data() {
		return {
			mobile: '',
			code: '',
			mobile_code: '',
			password: '',
			password2: '',

			time: 60,
			isGetCode: false, //是否获取验证码
			timer: '', //定时器

			isShowPassword1: false,
			isShowPassword2: false, //是否显示验证码
		}
	},
	mounted() {
		this.getUserInfo()
		let userinfo = this.$store.state.userinfo;
		// this.mobile = userinfo.mobile;
		// this.mobile_code = userinfo.mobile_code;
	},
	destroyed() {
		clearInterval(this.timer);
	},

	methods: {
		// 获取会员信息
		getUserInfo() {
			return new Promise((resolve, reject) => {
				this.$http.userInfo().then(res => {
					if (res.code == 1) {
						this.mobile = res.data.mobile
						this.mobile_code = res.data.mobile_code
					}  else if (res.code == -201) {
					
				}else {
						this.$message.error(res.msg);
					}
					resolve();
				})
			})
		},
		// 获取验证码
		getCode() {
			let data = {
				type: 7,
				is_test: 1, //线上为0
				mobile_code: this.mobile_code,
				mobile: this.mobile,
			}

			this.$http.getCode(data).then(res => {
				if (res.code == 1) {
					this.$message.success(this.$t('sendSuccess'));
					this.$message.success(res.data.code);
					this.isGetCode = true;
					this.timer = setInterval(() => {
						if (this.time == 1) {
							clearInterval(this.timer);
							this.time = 60;
							this.isGetCode = false;
						}
						this.time--;
					}, 1000);
				} else {
					this.$message.error(res.msg);
				}
			}).catch((v) => {

			});
		},
		/* 校验验证码 */
		verifyCode() {
			if (!this.code) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}

			this.$http.verifyCode({
				mobile: this.mobile,
				code: this.code,
				type: 7,
				mobile_code: this.mobile_code,
			}).then(res => {
				if (res.code == 1) {
					this.modifyPassword();
				} else {
					this.$message.error(res.msg);
				}
			})
			// this.modifyPassword();
		},

		// 验证码修改密码
		forgetPassword() {
			if (!this.code) {
				this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				return;
			}
			if (!this.password) {
				this.$message.error(this.$t('tips.newPasswordCannotEmpty'));
				return;
			}
			if (!this.password2) {
				this.$message.error(this.$t('tips.confirmPasswordCannotEmpty'));
				return;
			}

			if (this.password != this.password2) {
				this.$message.error(this.$t('tips.theTwoPasswordsAreInconsistent'));
				return
			}
			this.$http.forgetPassword({
				password: this.password,
				code: this.code,
				mobile: this.mobile,
				mobile_code: this.mobile_code,

			}).then(res => {
				if (res.code == 1) {
					this.$message.success(res.msg);
					setTimeout(() => {
						this.clearInput();
						localStorage.removeItem('userinfo')
						this.$router.replace('/accountLogin');
					}, 2000)
				}else{
					this.$message.error(res.msg);

				}
			})
		},
		// 修改密码
		modifyPassword() {
			if (!this.password) {
				this.$message.error(this.$t('tips.newPasswordCannotEmpty'));
				return;
			}
			if (!this.password2) {
				this.$message.error(this.$t('tips.confirmPasswordCannotEmpty'));
				return;
			}



			if (this.password != this.password2) {
				this.$message.error(this.$t('tips.theTwoPasswordsAreInconsistent'));
				return;
			}



			let data = {
				password: this.password,
				password_code: this.password2,
			};
			this.$http.editPassword(data).then(res => {
				this.$message.success(res.msg);
				if (res.code == 1) {
					setTimeout(() => {
						this.clearInput();
						localStorage.removeItem('userinfo')
						this.$router.replace('/accountLogin');
					}, 2000)
				}
			})
		},
		// 清空输入框
		clearInput() {
			this.code = '';
			this.password = '';
			this.password2 = '';
			this.isGetCode = false;
			clearInterval(this.timer);
			this.time = 60;
		},

	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	// width: 926px;
}

.login_top {
	display: flex;
	padding: 32px 40px;
	border-bottom: 1px solid #F3F5F6;

	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
	}
}

.now_email {
	margin: 32px 40px;
	padding-left: 24px;
	display: flex;
	justify-content: left;
	align-items: center;
	width: 800px;
	height: 56px;
	background: repeating-linear-gradient(to right, rgba(243, 152, 0, 0.2), rgba(243, 152, 0, 0));

	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.email {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333FF;
		margin-left: 8px;
	}

	.email_num {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #F39800FF;
	}
}

.cur_account {
	color: #333333;
	font-size: 16px;
	text-align: left;
	padding: 48px 32px;
}

.input_item {
	margin-left: 40px;
	margin-bottom: 32px;
	text-align: left;
	display: flex;
	width: 540px;
	padding: 14px 24px;
	border: 1px solid #DEDEDE;
	font-size: 16px;
	position: relative;


	.input_item_icon {
		img {
			width: 20px;
			height: 20px;
		}
	}

	.input {
		padding-left: 16px;

		input {
			width: 400px;
			border: none;
			outline: none;
			font-size: 16px;
		}
	}

	.code {
		position: absolute;
		top: 13px;
		right: 24px;
		color: #4177DD;
		font-size: 16px;

		&:hover {
			cursor: pointer;
		}

	}

	.show_icon {
		position: absolute;
		top: 13px;
		right: 24px;

		img {
			width: 18px;
			height: 18px;
		}
	}
}

.btn {
	margin-left: 40px;
	text-align: center;
	line-height: 48px;
	background: #F39800;
	opacity: 1;
	width: 300px;
	height: 48px;
	border-radius: 4px;
	font-size: 16px;
	color: #FFFFFF;

	&:hover {
		cursor: pointer;
	}
}
</style>
